// src/components/PrivateRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks';
import Layout from "./Layout";

interface PrivateRouteProps {
    element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
    const { isAuthenticated } = useAuth();
    console.log("isAuthenticated", isAuthenticated);

    return isAuthenticated ? <Layout>
        {/* <Outlet /> */}
        {element}
    </Layout> : <Navigate to="/login" />;
};

export default PrivateRoute;
