import React, { useState } from 'react';
import Select from 'react-select';
import { useTempStore } from "../../hooks";

// Define the Student type
interface Student {
    id: number;
    name: string;
    classId: string;
}

// Define the Exam type
interface Exam {
    id: number;
    name: string;
    className: string;
    students: Student[];
}

// interface CreateExamProps {
//     exams: Exam[];
//     setExams: React.Dispatch<React.SetStateAction<Exam[]>>;
// }

const studentsData: Student[] = [
    { id: 1, name: 'John Doe', classId: '8A' },
    { id: 2, name: 'Jane Smith', classId: '8A' },
    { id: 3, name: 'Mark Johnson', classId: '9B' },
    { id: 4, name: 'Emily Davis', classId: '9B' },
];

const CreateExam: React.FC<any> = () => {
    const [examName, setExamName] = useState('');
    const [selectedClass, setSelectedClass] = useState<{ label: string; value: string; } | null>(null);
    const [selectedStudents, setSelectedStudents] = useState<Student[]>([]);
    const [exams, setExams, clearExams] = useTempStore<Exam[]>('exams', []);


    const handleCreateExam = () => {
        if (examName && selectedClass && selectedStudents.length > 0) {
            const newExam: Exam = {
                id: exams.length + 1,
                name: examName,
                className: selectedClass.label,
                students: selectedStudents,
            };
            setExams((prevExams) => [...prevExams, newExam]);
            setExamName('');
            setSelectedClass(null);
            setSelectedStudents([]);
            alert('Exam created successfully!');
        }
    };

    const classOptions = [
        { label: 'Class 8 - Section A', value: '8A' },
        { label: 'Class 9 - Section B', value: '9B' },
    ];

    return (
        <div className="p-4 m-6 bg-white dark:bg-darkBg shadow-lg rounded-lg">
            <h1 className="text-2xl font-semibold mb-4">Create an Exam</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                    <label>Exam Name</label>
                    <input
                        type="text"
                        className="w-full p-2 mt-2 border rounded-md"
                        value={examName}
                        onChange={(e) => setExamName(e.target.value)}
                    />
                </div>
                <div>
                    <label>Class</label>
                    <Select
                        value={selectedClass}
                        onChange={(selected) => {
                            setSelectedClass(selected);
                            setSelectedStudents(
                                studentsData.filter((student) => student.classId === selected?.value)
                            );
                        }}
                        options={classOptions}
                        placeholder="Select class"
                        className="w-full mt-2"
                    />
                </div>
            </div>

            <button
                onClick={handleCreateExam}
                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full mt-4"
            >
                Create Exam
            </button>

            <h2 className="text-xl font-semibold mt-6">Created Exams</h2>
            <ul className="mt-4">
                {exams.map((exam) => (
                    <li key={exam.id} className="border-b py-2">
                        {exam.name} ({exam.className})
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CreateExam;
