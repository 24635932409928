import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMoon, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Add logout icon
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
    toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Perform any logout logic (e.g., clear tokens)
        navigate('/login'); // Redirect to login page after logout
    };

    return (
        <header className="fixed top-0 left-0 w-full z-50 bg-white dark:bg-darkBg shadow-sm flex justify-between items-center p-4">
            {/* Sidebar Toggle Button */}
            <button onClick={toggleSidebar} className="p-2">
                <FontAwesomeIcon icon={faBars} />
            </button>

            {/* Logo or Branding */}
            <h1 className="text-2xl font-bold">CurioSchools</h1>

            {/* Right Menu Items */}
            <div className="flex space-x-4 items-center">
                {/* Dark Mode Toggle */}
                <button className="p-2">
                    <FontAwesomeIcon icon={faMoon} className="dark:text-white" />
                </button>

                {/* User Profile */}
                <button className="p-2">
                    <FontAwesomeIcon icon={faUser} className="dark:text-white" />
                </button>

                {/* Logout */}
                <button onClick={handleLogout} className="p-2">
                    <FontAwesomeIcon icon={faSignOutAlt} className="dark:text-white" />
                </button>
            </div>
        </header>
    );
};

export default Header;
