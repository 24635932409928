// services/authService.ts
import api from './api';

export interface LoginCredentials {
    email: string;
    password: string;
}

export const login = async (credentials: LoginCredentials) => {
    try {
        const response = await api.post('/auth/login', credentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const signup = async (credentials: LoginCredentials) => {
    const response = await api.post('/users/signup', credentials);
    return response.data;
    // throw new Error('Signup failed');
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const setToken = (token: string) => {
    localStorage.setItem('token', token);
};

export const logout = () => {
    // localStorage.removeItem('token');
    localStorage.clear();
};