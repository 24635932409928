import { useCallback, useEffect, useRef, useState, useMemo } from 'react';

function debounce(fn: Function, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (...args: any) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
    };
}

interface IApiResponse<TData = any> {
    status: number;
    data: TData;
    error?: any;
    message?: string;
}

interface PaginationState {
    showPagination: boolean;
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
}

interface UseApiOptions<TData = any, TError = Error> {
    enabled?: boolean;
    onSuccess?: (data: IApiResponse) => void;
    onError?: (error: TError) => void;
    onMutate?: (variables: any) => void;
    onSettled?: (data: IApiResponse | undefined, error: TError | null) => void;
    retry?: number | boolean | ((failureCount: number, error: TError) => boolean);
    retryDelay?: number | ((retryAttempt: number) => number);
    staleTime?: number;
    cacheTime?: number;
    refetchInterval?: number | false;
    refetchIntervalInBackground?: boolean;
    refetchOnWindowFocus?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnMount?: boolean;
    suspense?: boolean;
    fakeKEY?: TData;
}

type ApiFunction<TData = any, TVariables = any> = (variables?: TVariables) => Promise<IApiResponse<TData>>;
type QueryStatus = 'idle' | 'loading' | 'error' | 'success';

interface QueryResult<TData = any, TError = Error> {
    data: TData | undefined;
    dataUpdatedAt: number;
    error: TError | null;
    errorUpdatedAt: number;
    failureCount: number;
    errorUpdateCount: number;
    isError: boolean;
    isFetched: boolean;
    isFetchedAfterMount: boolean;
    isFetching: boolean;
    isIdle: boolean;
    isLoading: boolean;
    isLoadingError: boolean;
    isPlaceholderData: boolean;
    isPreviousData: boolean;
    isRefetchError: boolean;
    isRefetching: boolean;
    isStale: boolean;
    isSuccess: boolean;
    status: QueryStatus;
    remove: () => void;
    refetch: () => Promise<QueryResult<TData, TError>>;
    cancelQuery: () => void;
}
// refetch: async () => ({} as QueryResult<TData, TError>),

function useApiBase<TData = any, TError = Error, TVariables = any>(
    key: string | string[],
    fn: ApiFunction<TData, TVariables>,
    options: UseApiOptions<TData, TError> = {}
): QueryResult<TData, TError> & {
    pagination: PaginationState & {
        handlePageChange: (pageNumber: number) => void;
        handleLimitChange: (pageSize: number) => void;
    };
    mutate: (variables?: TVariables) => Promise<void>;
} {
    const {
        enabled = true,
        onSuccess,
        onError,
        onMutate,
        onSettled,
        retry = 3,
        retryDelay = 1000,
        staleTime = 0,
        cacheTime = 5 * 60 * 1000,
        refetchInterval = false,
        refetchIntervalInBackground = false,
        refetchOnWindowFocus = true,
        refetchOnReconnect = true,
        refetchOnMount = true,
        suspense = false,
    } = options;

    const [queryState, setQueryState] = useState<QueryResult<TData, TError>>({
        data: undefined,
        dataUpdatedAt: 0,
        error: null,
        errorUpdatedAt: 0,
        failureCount: 0,
        errorUpdateCount: 0,
        isError: false,
        isFetched: false,
        isFetchedAfterMount: false,
        isFetching: false,
        isIdle: true,
        isLoading: false,
        isLoadingError: false,
        isPlaceholderData: false,
        isPreviousData: false,
        isRefetchError: false,
        isRefetching: false,
        isStale: false,
        isSuccess: false,
        status: 'idle',
        remove: () => { },
        refetch: async () => ({} as QueryResult<TData, TError>),
        cancelQuery: () => { },
    });

    const [pagination, setPagination] = useState<PaginationState>({
        showPagination: false,
        totalItems: 1,
        totalPages: 1,
        pageNumber: 1,
        pageSize: 10
    });

    const mountedRef = useRef(false);
    const queryCache = useRef(new Map<string, { data: TData; timestamp: number; }>());

    const queryKey = useMemo(() => Array.isArray(key) ? key.join('-') : key, [key]);

    const updateQueryState = useCallback((updates: Partial<QueryResult<TData, TError>>) => {
        setQueryState(prev => ({ ...prev, ...updates }));
    }, []);

    const updatePagination = useCallback((newData: Partial<PaginationState>) => {
        setPagination(prev => ({ ...prev, ...newData }));
    }, []);

    const handlePageChange = useCallback((pageNumber: number) => {
        updatePagination({ pageNumber: pageNumber });
        debouncedFetch(); // Trigger debounced API call on page change
    }, [updatePagination]);

    const handleLimitChange = useCallback((pageSize: number) => {
        updatePagination({ pageSize: pageSize });
        debouncedFetch(); // Trigger debounced API call on page size change
    }, [updatePagination]);

    const debouncedFetch = useCallback(
        debounce(() => {
            mutate();
        }, 300), // 300ms debounce time
        [] // You can customize debounce delay as needed
    );

    const executeFetch = useCallback(async (variables?: TVariables): Promise<QueryResult<TData, TError>> => {
        try {
            console.log("executeFetch", variables);
            onMutate?.(variables);
            const apiRes = await fn({ ...variables } as TVariables);
            console.log('executeFetch apiRes', apiRes);
            if (apiRes.status === 200 || apiRes.status === 201) {
                const newData: any = apiRes.data;
                let items = [];
                queryCache.current.set(queryKey, { data: newData, timestamp: Date.now() });

                if ('items' in newData && Array.isArray(newData.items)) {
                    items = newData.items;
                    updatePagination({
                        pageNumber: (newData as any).pageNumber,
                        pageSize: (newData as any).pageSize,
                        totalItems: (newData as any).totalItems,
                        totalPages: (newData as any).totalPages,
                        showPagination: true
                    });
                }

                const newState: QueryResult<TData, TError> = {
                    ...queryState,
                    data: items ? items : newData,
                    dataUpdatedAt: Date.now(),
                    status: 'success',
                    isSuccess: true,
                    isError: false,
                    error: null,
                    isFetched: true,
                    isFetchedAfterMount: mountedRef.current,
                    isLoading: false,
                    isFetching: false,
                };

                updateQueryState(newState);
                onSuccess?.(apiRes);
                onSettled?.(apiRes, null);
                return newState;
            } else {
                throw new Error(apiRes.error);
            }
        } catch (error: any) {
            console.log('executeFetch error', error);
            // console.error(error);
            const newState: QueryResult<TData, TError> = {
                ...queryState,
                status: 'error',
                isError: true,
                error: error as TError,
                errorUpdatedAt: Date.now(),
                failureCount: queryState.failureCount + 1,
                errorUpdateCount: queryState.errorUpdateCount + 1,
                isLoadingError: !queryState.isFetched,
                isRefetchError: queryState.isFetched,
                isLoading: false,
                isFetching: false,
            };

            updateQueryState(newState);
            onError?.(error as TError);
            onSettled?.(undefined, error as TError);
            return newState;
        }
    }, [queryKey, queryState, fn, updateQueryState, updatePagination]); // eslint-disable-line react-hooks/exhaustive-deps

    const mutate = useCallback(async (variables?: TVariables) => {
        console.log("useApi mutate");
        updateQueryState({
            status: 'loading',
            isLoading: true,
            isFetching: true,
            isIdle: false,
        });

        await executeFetch(variables);
    }, [executeFetch, updateQueryState]);

    const refetch = useCallback(async () => {
        console.log("useApi refetch");
        return executeFetch();
        // return executeFetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mountedRef.current = true;

        if (enabled && queryState.isIdle) {
            debouncedFetch(); // Use debounced fetch on mount
        }

        return () => {
            mountedRef.current = false;
        };
    }, [enabled, mutate, queryState.isIdle, debouncedFetch]);

    return {
        ...queryState,
        pagination: {
            ...pagination,
            handlePageChange,
            handleLimitChange,
        },
        mutate,
        refetch: executeFetch,
        remove: () => { },
    };
}


export function useApi<TData = any, TError = Error, TVariables = any>(
    key: string | string[],
    fn: ApiFunction<TData, TVariables>,
    options?: UseApiOptions<TData, TError>
) {
    return useApiBase<TData, TError, TVariables>(key, fn, { ...options, enabled: options?.enabled ?? true });
}

export function useMutation<TData = any, TError = Error, TVariables = any>(
    key: string | string[],
    fn: ApiFunction<TData, TVariables>,
    options?: UseApiOptions<TData, TError>
) {
    return useApiBase<TData, TError, TVariables>(key, fn, { ...options, enabled: false });
}