// src/context/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getToken, login, signup, logout, setToken } from '../services/authService';

// Define the AuthContext properties
type IUserRole = 'admin' | 'teacher' | 'student' | 'school' | '';
const localStorageKey = 'testDashboardUser';

// Define the AuthContext properties
interface AuthContextProps {
    isAuthenticated: boolean;
    login: (email: string, password: string, cb?: (success: boolean, message?: string, data?: Record<string, any>) => void) => Promise<void>;
    signup: (email: string, password: string) => Promise<void>;
    logout: () => void;
    userRole: IUserRole;
    userData: any;
}

// Create the AuthContext
export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Define the props type to include children
interface AuthProviderProps {
    children: ReactNode;
}

// AuthProvider component that wraps the app
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(getToken() ? true : false);
    const [userData, setUserData] = useState<any>(null);
    const [userRole, setUserRole] = useState<IUserRole>('');

    useEffect(() => {
        const lsUser = localStorage.getItem(localStorageKey);
        if (lsUser) {
            const userTempData = JSON.parse(lsUser);
            setUserData(userTempData);
            setIsAuthenticated(true);
            setUserRole(userTempData.role);
        }
    }, []);

    const handleLogin = async (email: string, password: string, cb?: (success: boolean, message?: string, data?: Record<string, any>) => void) => {
        try {
            const response = await login({ email, password });
            if (response.success) {
                const userTempData = response.data;
                setUserData(userTempData);
                setIsAuthenticated(true);
                setUserRole(userTempData.role);
                if (cb) {
                    setToken(userTempData.token);
                    localStorage.setItem(localStorageKey, JSON.stringify(userTempData));
                    cb(true, 'Login successful', userTempData);
                }

            } else {
                if (cb) {
                    cb(false, response.message || 'Invalid credentials');
                }
            }
        } catch (error) {
            if (cb) {
                cb(false, 'Something went wrong, please try again');
            }
        }
    };

    const handleSignup = async (email: string, password: string) => {
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        setUserData(null);
        setUserRole('');
        localStorage.removeItem(localStorageKey);
        logout();
    };

    // const isAuthenticated = !!getToken();

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRole, userData, login: handleLogin, signup: handleSignup, logout: handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};



// Custom hook to use the AuthContext
// export const useAuth = (): AuthContextProps => {
//     const context = useContext(AuthContext);
//     if (!context) {
//         throw new Error('useAuth must be used within an AuthProvider');
//     }
//     return context;
// };
