import { useState, useEffect } from 'react';

export function useTempStore<T>(key: string, initialValue: T) {
    // Retrieve the initial value from sessionStorage or use the provided initial value
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn('Error retrieving from sessionStorage', error);
            return initialValue;
        }
    });

    // Update sessionStorage whenever the value changes
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            sessionStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.warn('Error setting sessionStorage', error);
        }
    };

    // Clear the value from sessionStorage
    const clearValue = () => {
        try {
            sessionStorage.removeItem(key);
            setStoredValue(initialValue);
        } catch (error) {
            console.warn('Error clearing sessionStorage', error);
        }
    };

    return [storedValue, setValue, clearValue] as const;
}

