import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faMicrophone, faStop, faTimes, faListAlt } from '@fortawesome/free-solid-svg-icons';
// import AssessmentResults from './AssessmentResults'; // Import the new AssessmentResults component
// import useWebSocket, { ReadyState } from 'react-use-websocket';
// import PronunciationAssessment from "../../components/PronunciationAssessment";
// import io, { Socket } from 'socket.io-client';
import { downsampleBuffer } from "../../utils";
import socket from "../../services/socket";

interface PronunciationAssessmentResult {
    text: string;
    pronunciationAssessment: {
        AccuracyScore: number;
        FluencyScore: number;
        CompletenessScore: number;
        PronunciationScore: number;
    };
}

// const socket: Socket = io('http://localhost:3090'); //

interface PopupStudent {
    id: number;
    name: string;
}

const sampleSpeeches = [
    "The quick brown fox jumps over the lazy dog.",
    "To be or not to be, that is the question.",
    "The only limit to our realization of tomorrow is our doubts of today.",
    "In the end, we will remember not the words of our enemies, but the silence of our friends.",
    "Success is not final, failure is not fatal: It is the courage to continue that counts.",
];

interface ErrorState {
    message: string;
}

const AssessmentPopup: React.FC<{ student: PopupStudent; onClose: () => void; }> = ({ student, onClose }) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [practiceSentence, setPracticeSentence] = useState<string>('');

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [error, setError] = useState<ErrorState | null>(null);

    // const [expectedText, setExpectedText] = useState<string>('Hello world');
    const [recognizingText, setRecognizingText] = useState<string>('');
    const [recognizedText, setRecognizedText] = useState<string>('');
    const [pronunciationAssessment, setPronunciationAssessment] = useState<PronunciationAssessmentResult | null>(null);

    const audioContextRef = useRef<AudioContext | null>(null);
    const mediaStreamRef = useRef<MediaStream | null>(null);
    const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
    const processorRef = useRef<ScriptProcessorNode | null>(null);

    // Real-time recognized text
    useEffect(() => {
        socket.on('recognizingText', (text: string) => {
            setRecognizingText(`Recognizing: ${text}`);
        });

        // Final recognized text and pronunciation assessment
        socket.on('recognizedText', (data: PronunciationAssessmentResult) => {
            setRecognizedText(`Recognized: ${data.text}`);
            setPronunciationAssessment(data);
        });

        socket.on('pronunciationResult', (data: PronunciationAssessmentResult) => {
            setRecognizedText(`Recognized: ${data.text}`);
            setPronunciationAssessment(data);
        });

        socket.on('error', (error: ErrorState) => {
            setError(error);
        });

        return () => {
            socket.off('recognizingText');
            socket.off('recognizedText');
            socket.off('pronunciationResult');
            socket.off('error');
        };
    }, []);

    const startPronunciationAssessment = () => {
        socket.emit('startPronunciationAssessment', { expectedText: practiceSentence });
        setIsRecording(true);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                audioContextRef.current = new (window.AudioContext)();
                mediaStreamRef.current = stream;
                sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
                processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);

                processorRef.current.onaudioprocess = (e: AudioProcessingEvent) => {
                    const inputData = e.inputBuffer.getChannelData(0);
                    const intData = downsampleBuffer(inputData, audioContextRef.current!.sampleRate, 16000);
                    socket.emit('audioChunk', intData);
                };

                sourceRef.current.connect(processorRef.current);
                processorRef.current.connect(audioContextRef.current.destination);
            })
            .catch((err) => {
                console.error('Error accessing microphone:', err);
            });
    };

    const stopPronunciationAssessment = () => {
        setIsRecording(false);
        if (processorRef.current && sourceRef.current) {
            processorRef.current.disconnect();
            sourceRef.current.disconnect();
            processorRef.current.onaudioprocess = null;
        }
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
        }
        socket.emit('stopPronunciationAssessment');
    };


    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };



    const handleAudioUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setAudioFile(e.target.files[0]);
        }
    };

    const handleSelectSpeech = () => {
        const randomSpeech = sampleSpeeches[Math.floor(Math.random() * sampleSpeeches.length)];
        setPracticeSentence(randomSpeech);
    };

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center p-4">
            <div className="bg-white dark:bg-darkBg rounded-lg shadow-lg w-full max-w-4xl relative">

                {/* Close Button */}
                <button
                    className="absolute top-4 right-4 text-3xl text-gray-600 dark:text-white cursor-pointer hover:text-red-500 transition duration-200"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                {/* Header */}
                <div className="px-6 py-4 bg-blue-500 rounded-t-lg">
                    <h2 className="text-2xl font-bold text-white">SpeechAI - Pronunciation Assessment Tool</h2>
                    <p className="text-sm text-gray-200 mt-2">Analyze your pronunciation accuracy using AI-powered assessment.</p>
                </div>

                {/* Body */}
                <div className="p-6 space-y-6 bg-gray-50 dark:bg-gray-800">

                    {/* Student Info */}
                    <div className="p-4 bg-white dark:bg-darkBg shadow rounded-lg">
                        <h3 className="font-semibold text-xl text-gray-800 dark:text-white">
                            <span className="text-blue-600 dark:text-blue-400">Student: </span>
                            {student.name}
                        </h3>
                    </div>

                    {/* Card for Input Sentence and Upload */}
                    <div className="p-4 bg-white dark:bg-darkBg shadow rounded-lg space-y-4">
                        <div className="flex justify-between items-center">
                            <h3 className="font-semibold text-lg text-gray-800 dark:text-white">Enter Your Practice Sentence and Upload Audio</h3>
                            <label className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition flex items-center space-x-2 cursor-pointer">
                                <FontAwesomeIcon icon={faUpload} />
                                <span>Upload Audio</span>
                                <input
                                    type="file"
                                    accept="audio/*"
                                    onChange={handleAudioUpload}
                                    className="hidden"
                                />
                            </label>
                        </div>

                        {/* Textarea and Select Speech Button */}
                        <div className="flex space-x-2">
                            <textarea
                                value={practiceSentence}
                                onChange={(e) => setPracticeSentence(e.target.value)}
                                placeholder="Type your sentence here..."
                                className="w-full p-3 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white"
                            ></textarea>
                            <button
                                onClick={handleSelectSpeech}
                                className="bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-white px-4 py-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-500 transition flex items-center space-x-2"
                            >
                                <FontAwesomeIcon icon={faListAlt} />
                                <span>Select Speech</span>
                            </button>
                        </div>

                        <div className="w-full bg-gray-200 rounded-full h-4">
                            <div className="bg-green-500 h-full rounded-full" style={{ width: audioFile ? '100%' : '0%' }}></div>
                        </div>
                        {audioFile && <p className="text-gray-600 dark:text-gray-400">Uploaded: {audioFile.name}</p>}
                    </div>

                    {/* Start Recording Button */}
                    <div className="flex justify-center">
                        <button
                            // onClick={togglePlayPause}
                            disabled={!practiceSentence}
                            onClick={isRecording ? stopPronunciationAssessment : startPronunciationAssessment}
                            className="bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600 transition flex items-center space-x-2"
                        >
                            <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />
                            <span>{isRecording ? 'Stop Recording' : 'Start Recording'}</span>
                        </button>
                    </div>
                    <div className="w-full h-20">
                        {!pronunciationAssessment && isRecording && <>
                            <p>{recognizingText}</p>
                            <p>{recognizedText}</p>
                        </>}
                        {pronunciationAssessment && !isRecording && (
                            <p>
                                Pronunciation Assessment:
                                {JSON.stringify(pronunciationAssessment, null, 2)}
                            </p>
                        )}
                    </div>


                    {error && (
                        <div style={{
                            backgroundColor: '#fee2e2',
                            borderColor: '#f87171',
                            borderWidth: '1px',
                            borderRadius: '0.25rem',
                            padding: '1rem',
                            color: '#b91c1c'
                        }}>
                            <p style={{ fontWeight: 'bold' }}>Error</p>
                            <p>{error.message}</p>
                        </div>
                    )}

                    {/* Import AssessmentResults */}
                    {/* <AssessmentResults /> */}
                    {/* <PronunciationAssessment /> */}
                </div>

                {/* Footer */}
                <div className="px-6 py-4 bg-gray-100 dark:bg-gray-700 flex justify-end rounded-b-lg">
                    <button
                        onClick={onClose}
                        className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600 transition flex items-center space-x-2"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                        <span>Close</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AssessmentPopup;
