import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

// export const useAuth = () => {
//     return useContext(AuthContext);
// };

// Custom hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};