import React, { useState } from 'react';
import AssessmentPopup from './AssessmentPopup';
import { useTempStore } from "../../hooks";
import SpeechToText from "../../components/SpeechToText";

interface Student {
    id: number;
    name: string;
}

interface Exam {
    id: number;
    name: string;
    className: string;
    students: Student[];
}

const TakeExam: React.FC<any> = () => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [currentStudent, setCurrentStudent] = useState<Student | null>(null);
    const [exams, setExams, clearExams] = useTempStore<Exam[]>('exams', []);
    const handleStudentClick = (student: Student) => {
        setCurrentStudent(student);
        setShowPopup(true);
    };

    return (
        <div className="p-6 m-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
            <h1 className="text-2xl font-bold mb-6">Take Exam</h1>

            <div className="space-y-4">
                {/* <SpeechToText /> */}
            </div>

            {/* Exams List */}
            {exams.length > 0 ? (
                <div className="space-y-4">
                    {exams.map((exam) => (
                        <div key={exam.id} className="p-4 bg-gray-100 dark:bg-gray-700 rounded-md shadow">
                            <h2 className="text-xl font-semibold">{exam.name}</h2>
                            <p className="text-gray-600 dark:text-gray-400">Class: {exam.className}</p>

                            <h3 className="mt-4 font-semibold">Students:</h3>
                            <ul className="list-disc pl-6">
                                {exam.students.map((student) => (
                                    <li key={student.id}>
                                        <span
                                            onClick={() => handleStudentClick(student)}
                                            className="text-blue-500 dark:text-blue-400 cursor-pointer hover:underline"
                                        >
                                            {student.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-gray-600 dark:text-gray-400">No exams available.</p>
            )}

            {/* Assessment Popup */}
            {showPopup && currentStudent && (
                <AssessmentPopup
                    student={currentStudent}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
};

export default TakeExam;
