import React, { useEffect, useState } from "react";
import { useApi, useMutation } from "../../hooks/useNew";
import { useToast } from "../../hooks";
import {
    getSchools,
    addSchool,
    updateSchool,
} from "../../services/schoolService";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/react-table";
import Modal from "../../components/Modal";

interface Teacher {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
}

const columns: ColumnDef<Teacher>[] = [
    {
        accessorKey: "name",
        header: "Name",
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: "email",
        header: "Email",
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        cell: (info) => info.getValue(),
    },
];

const Teachers: React.FC = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const { showToast, dismissToast } = useToast();

    console.log("Teachers");

    const {
        data: teachers = [],
        isLoading: isFetchingTeachers,
        refetch: fetchTeachers,
        pagination,
    } = useApi(
        "TeacherList",
        () =>
            getSchools({
                query: "",
                pageNumber: 1,
                pageSize: 10,
            }),
        {
            enabled: false,
            onSuccess: (response: any) => {
                console.log(response);
                return response;
            },
            onError: (error: Error) => {
                showToast("error", error.message);
            },
        },
    );

    const { mutate: addTeacherMutation, isLoading: isAddingTeacher } =
        useMutation("AddTeacher", (data) => addSchool(data), {
            onSuccess: (response: any) => {
                showToast("success", response.message);
                closeModal();
                fetchTeachers();
            },
            onError: (error: any) => {
                showToast("error", error.error || error.message);
            },
            onMutate: (data) => {
                console.log(data);
                showToast("info", "Adding Teacher...", "AddTeacher");
            },
            onSettled: () => {
                dismissToast("AddTeacher");
            },
        });

    const { mutate: updateTeacherMutation, isLoading: isUpdatingTeacher } =
        useMutation(
            "UpdateTeacher",
            (data) =>
                updateSchool(data.id, {
                    ...data,
                    id: undefined,
                }),
            {
                onSuccess: (response: any) => {
                    showToast("success", response.message);
                    closeModal();
                    fetchTeachers();
                },
                onError: (error: any) => {
                    showToast("error", error.error || error.message);
                },
                onMutate: (data) => {
                    console.log(data);
                    showToast("info", "Updating Teacher...", "UpdateTeacher");
                },
                onSettled: () => {
                    dismissToast("UpdateTeacher");
                },
            },
        );

    useEffect(() => {
        console.log("Teachers useEffect");
        fetchTeachers();
    }, []);

    // Function to open the modal
    const openModal = (data?: any) => {
        setModalOpen(true);
        if (data) {
            setTeacher(data);
        } else {
            setTeacher({
                id: "",
                name: "",
                email: "",
                phoneNumber: "",
            });
        }
    };

    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
    };

    const [teacher, setTeacher] = useState<Teacher>({
        id: "",
        name: "",
        email: "",
        phoneNumber: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTeacher({ ...teacher, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const userName = "SCH" + new Date().valueOf().toString();
        if (!teacher.id) {
            addTeacherMutation({
                ...teacher,
                id: undefined,
                username: userName,
                password: "123456",
            });
        } else {
            updateTeacherMutation({
                id: teacher.id,
                name: teacher.name,
                email: teacher.email,
                phoneNumber: teacher.phoneNumber,
                password: undefined,
            });
        }
    };

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Teachers</h1>
                <button
                    onClick={() => openModal()}
                    className="p-2 bg-blue-500 text-white rounded"
                >
                    Add Teacher
                </button>
            </div>

            <Table
                columns={columns}
                data={teachers}
                onRowClick={(row) => {
                    openModal(row);
                }}
            />

            {/* Modal Component */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="medium"
            >
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={teacher.name}
                                onChange={handleInputChange}
                                className="w-full border p-2 rounded"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={teacher.email}
                                onChange={handleInputChange}
                                className="w-full border p-2 rounded"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Phone Number</label>
                            <input
                                type="text"
                                name="phoneNumber"
                                value={teacher.phoneNumber}
                                onChange={handleInputChange}
                                className="w-full border p-2 rounded"
                                required
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                disabled={isAddingTeacher || isUpdatingTeacher}
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                                onClick={() => closeModal()}
                            >
                                Close
                            </button>
                            <button
                                disabled={isAddingTeacher || isUpdatingTeacher}
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                            >
                                {teacher.id ? "Update" : "Add"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default Teachers;
